import React, { useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { TonConnectButton, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';

const TonGiveaway = () => {
  const { id, balance, setBalance, tonTransactions, setTonTransactions } = useUser();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Participate");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [loading, setLoading] = useState(false);  // Loading state for spinner
  const wallets = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const rewardOkapi = 1000000; // 1 Million Okapi
  const minimumTransaction = 1 * 1e9; // 1 TON in nanotons

  useEffect(() => {
    const initializeTonConnect = async () => {
      try {
        await tonConnectUI.connectionRestored;
      } catch (err) {
        console.error('TonConnect initialization error:', err);
        setMessage(`TonConnect error: ${err.message}`);
        setMessageColor("red");
      }
    };
    initializeTonConnect();
  }, [tonConnectUI]);

  const transaction = (amount) => ({
    validUntil: Math.floor(Date.now() / 1000) + 300,
    messages: [
      {
        address: process.env.REACT_APP_TON_WALLET_ADDRESS,
        amount,
      },
    ],
  });

  const handleParticipation = async () => {
    setButtonText("Processing...");
    setButtonDisabled(true);
    setLoading(true); // Start loading

    try {
      const response = await tonConnectUI.sendTransaction(transaction(minimumTransaction));
      console.log('Transaction successful:', response);

      const newBalance = balance + rewardOkapi;
      const userRef = doc(db, 'telegramUsers', id.toString());
      await updateDoc(userRef, {
        balance: newBalance,
        tonTransactions: tonTransactions + 1,
      });
      setBalance(newBalance);
      setTonTransactions(tonTransactions + 1);

      setMessage(`Congratulations! You earned ${rewardOkapi.toLocaleString()} Okapi.`);
      setMessageColor("green");
    } catch (err) {
      console.error('Transaction failed:', err);
      setMessage(`Transaction failed: ${err.message}. Please try again later.`);
      setMessageColor("red");
    } finally {
      setButtonText("Participate");
      setButtonDisabled(false);
      setLoading(false); // End loading
    }
  };

  return (
    <div className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
      <div className="flex h-full flex-1 flex-col justify-center relative">
        <div className="flex w-full flex-col justify-between h-full space-y-1">
          <h1 className="text-[15px] font-medium">100 TON Giveaway</h1>
          <p className="text-secondary text-[13px]">
            Make a TON transaction of at least 1 TON and earn 1 million Okapi in Giveaway!
          </p>
        </div>
      </div>
      <div className="w-fit flex items-center justify-end text-[14px]">
        {wallets ? (
          <button
            onClick={handleParticipation}
            className={`bg-[#1f2023] hover:bg-[#36373c] text-white w-fit py-[10px] px-5 rounded-[30px] font-semibold ease-in duration-200`}
            disabled={buttonDisabled}
          >
            {loading ? (
              <div className="spinner"></div>
            ) : (
              buttonText
            )}
          </button>
        ) : (
          <TonConnectButton />
        )}
      </div>
      {message && (
        <p className="w-full text-center text-[13px]" style={{ color: messageColor }}>
          {message}
        </p>
      )}
    </div>
  );
};

export default TonGiveaway;