import React, { useEffect, useState } from 'react';
import Animate from '../Components/Animate';
import Spinner from '../Components/Spinner';
import { useUser } from '../context/userContext';
import NewUser from '../Components/NewUser';
import CommunitySlider from '../Components/Slides';
import TasksMenu from '../Components/TasksMenu';
import YourRewards from '../Components/YourRewards';
import { NavLink } from 'react-router-dom';
import TonGiveaway from '../Components/TonGiveaway'; // Importing TonGiveaway

const AlphaDogs = () => {
  const { balance, loadingTwo, setLoadingTwo, checker, setChecker, loading } = useUser();
  const [checking, setChecking] = useState(false);
  const [welcome, setWelcome] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingTwo(false);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  const checkers = () => {
    setWelcome(false);
    setChecking(true);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Animate>
          <div className="w-full flex justify-center flex-col px-4">
            <div id="refer" className="w-full h-screen pt-5 scroller space-y-3 overflow-y-auto pb-[180px]">
              {loadingTwo && (
                <div className="flex justify-center items-center h-full">
                  <div className="w-[50px] h-[50px] border-[6px] border-[#6c6c6c] mt-[-20%] border-dashed rounded-full animate-spin marco"></div>
                </div>
              )}

              <div className={`w-full overflow-hidden space-y-3 ${loadingTwo ? 'hidden' : 'block'}`}>
                {/* Other content */}
                <div className='w-full h-[60px] overflow-hidden rounded-[12px] bg-btn flex items-center justify-center text-center font-semibold relative'>
                  <img src='/scorebg.svg' alt='ifd' className='object-cover object-center w-[400px] h-[220px] absolute left-[6px] mt-[-10px] right-0'/>
                  <div className='relative flex items-center justify-center text-white space-x-2'>
                    <img src='/scoreIcon2.svg' alt='score' className='w-[20px]' />
                    <span>Okapi Farming App</span>
                  </div>
                </div>

                <div className='w-full flex justify-center bg-[#181818] rounded-[12px] relative overflow-hidden'>
                  <img src='/blurbg.svg' alt='ifd' className='object-cover absolute left-0 w-full right-0 h-[200px] object-center rounded-[12px]'/>
                  <div className='w-full relative flex flex-col px-6 pt-6 items-center'>
                    <img src='stars.svg' alt='stars' className='w-[60px]' />
                    <h4 className='font-semibold text-[32px] pt-2'>{formatNumber(balance)}</h4>
                    <p className='pb-6'>Okapi Farming App</p>
                    <div className='w-full pb-4'>
                      <NavLink to='/airdrop' className='bg-btn h-[50px] px-4 rounded-[10px] flex items-center justify-center space-x-1 font-semibold text-[15px]'>
                        <img src='/tons.svg' alt='dfd' className='w-[26px] mt-[2px]' />
                        <span>Withdraw to wallet</span>
                      </NavLink>
                    </div>
                  </div>
                </div>

                <CommunitySlider />
                <div className='w-full flex flex-col'>
                  
                                  {/* Now adding the TON Giveaway section after YourRewards */}
                <TonGiveaway /> {/* TON Giveaway section will appear below the task and rewards */}
                  <TasksMenu />
                  
                  <YourRewards />
                </div>


              </div>

              {/* Additional checker logic here */}
            </div>
          </div>
        </Animate>
      )}
    </>
  );
};

export default AlphaDogs;